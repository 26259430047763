
import { Component, Vue } from 'vue-property-decorator';

export default class extends Vue {
  private handleClickHome() {
    this.$router.push('/');
  }

  private handleClickreserve() {
    this.$router.push({ name: 'mypageOrder' });
  }
}
